export class AppNoticeManager
   constructor: ->
      @selectedNotice = ko.observable(null)
      @coveredNotices = ko.observableArray()
      @coveredDismissableNotices = ko.observableArray()

      ###
      Order of display impact facts:
      1) Non-Dismissable
      2) Priority
      3) Freshness
      ###

   maybeTriggerNotice: (newNotice) =>
      assertArgs(arguments, Object)
      return @selectedNotice(newNotice) unless @selectedNotice()?
      if !@selectedNotice().dismissable
         if newNotice.dismissable
            if newNotice.priority >= @selectedNotice().priority
               @coveredNotices().unshift(@selectedNotice())
               @selectedNotice(newNotice)
            else
               indexToInsert = 0
               for notice in @coveredNotices()
                  if notice.priority < newNotice.priority
                     indexToInsert = @coveredNotices().indexOf(notice)
                     break
               @coveredNotices().splice(indexToInsert, 0, newNotice)
         else
            indexToInsert = 0
            for notice in @coveredDismissableNotices()
               if notice.priority < newNotice.priority
                  indexToInsert = @coveredDismissableNotices().indexOf(notice)
                  break
            @coveredDismissableNotices().splice(indexToInsert, 0, newNotice)
      else
         if !newNotice.dismissable or newNotice.priority >= @selectedNotice().priority
            @coveredDismissableNotices().unshift(@selectedNotice())
            @selectedNotice(newNotice)
         else
            indexToInsert = 0
            for notice in @coveredDismissableNotices()
               if notice.priority < newNotice.priority
                  indexToInsert = @coveredDismissableNotices().indexOf(notice)
                  break
            @coveredDismissableNotices().splice(indexToInsert, 0, newNotice)

   dismissActiveNotice: (callback) =>
      assertArgs(arguments, optional(Function))
      if @coveredNotices().length != 0
         @selectedNotice(@coveredNotices().shift())
      else if @coveredDismissableNotices().length != 0
         @selectedNotice(@coveredDismissableNotices().shift())
      else
         @selectedNotice(null)
      callback() if callback?

   clearAllNotices: (callback) =>
      assertArgs(arguments, optional(Function))
      @coveredNotices([])
      @coveredDismissableNotices([])
      @selectedNotice(null)
      callback() if callback?


AppNoticeManager.Error = {
   INVALID_NOTICE: "The passed notice is invalid"
}


export appNoticeManager = new AppNoticeManager()