import ko from "knockout";
import iconColorBarrier from "./icon-color-barrier.pug";
import iconColorCone from "./icon-color-cone.pug";
import iconColorCrane from "./icon-color-crane.pug";
import iconColorDeskChair from "./icon-color-desk-chair.pug";
import iconColorEngineer from "./icon-color-engineer.pug";
import iconColorHardHat from "./icon-color-hard-hat.pug";
import iconColorHouse from "./icon-color-house.pug";
import iconColorLightbulb from "./icon-color-lightbulb.pug";
import iconColorLoader from "./icon-color-loader.pug";
import iconColorMapPoint from "./icon-color-map-point.pug";
import iconColorNight from "./icon-color-night.pug";
import iconColorOffice from "./icon-color-office.pug";
import iconColorPainting from "./icon-color-painting.pug";
import iconColorPipes from "./icon-color-pipes.pug";
import iconColorPlane from "./icon-color-plane.pug";
import iconColorSkyline from "./icon-color-skyline.pug";
import iconColorTelecom from "./icon-color-telecom.pug";
import iconColorTools from "./icon-color-tools.pug";
import iconColorTruck from "./icon-color-truck.pug";
import iconColorWarehouse from "./icon-color-warehouse.pug";
import iconColorWorker from "./icon-color-worker.pug";

export function registerIcons(): void {
   ko.components.register("icon-color-barrier", {
      template: iconColorBarrier(),
   });

   ko.components.register("icon-color-cone", {
      template: iconColorCone(),
   });

   ko.components.register("icon-color-crane", {
      template: iconColorCrane(),
   });

   ko.components.register("icon-color-desk-chair", {
      template: iconColorDeskChair(),
   });

   ko.components.register("icon-color-engineer", {
      template: iconColorEngineer(),
   });

   ko.components.register("icon-color-hard-hat", {
      template: iconColorHardHat(),
   });

   ko.components.register("icon-color-house", {
      template: iconColorHouse(),
   });

   ko.components.register("icon-color-lightbulb", {
      template: iconColorLightbulb(),
   });

   ko.components.register("icon-color-loader", {
      template: iconColorLoader(),
   });

   ko.components.register("icon-color-map-point", {
      template: iconColorMapPoint(),
   });

   ko.components.register("icon-color-night", {
      template: iconColorNight(),
   });

   ko.components.register("icon-color-office", {
      template: iconColorOffice(),
   });

   ko.components.register("icon-color-painting", {
      template: iconColorPainting(),
   });

   ko.components.register("icon-color-pipes", {
      template: iconColorPipes(),
   });

   ko.components.register("icon-color-plane", {
      template: iconColorPlane(),
   });

   ko.components.register("icon-color-skyline", {
      template: iconColorSkyline(),
   });

   ko.components.register("icon-color-telecom", {
      template: iconColorTelecom(),
   });

   ko.components.register("icon-color-tools", {
      template: iconColorTools(),
   });

   ko.components.register("icon-color-truck", {
      template: iconColorTruck(),
   });

   ko.components.register("icon-color-warehouse", {
      template: iconColorWarehouse(),
   });

   ko.components.register("icon-color-worker", {
      template: iconColorWorker(),
   });
}
