enum Tier {
   NONE = "none",
   LOCAL = "local",
   DEV = "dev",
   STAGING = "staging",
   TRAINING = "training",
   SANDBOX = "sandbox",
   PROD = "prod",
}

const ORDERED_TIERS = [
   Tier.NONE,
   Tier.LOCAL,
   Tier.DEV,
   Tier.STAGING,
   Tier.SANDBOX,
   Tier.TRAINING,
   Tier.PROD,
];

type WindowWithEnvironment = Window & {
   LC_TIER?: Tier;
   config?: {
      LC_TIER: Tier;
   };
};

const windowWithEnvironment = window as WindowWithEnvironment;

export class Flags {
   static tier =
      windowWithEnvironment.LC_TIER || windowWithEnvironment.config?.LC_TIER || Tier.NONE;

   static enableThrough(tier: Tier): boolean {
      if (tier === Tier.NONE) return false;
      const maxTierIndex = ORDERED_TIERS.indexOf(tier);
      if (maxTierIndex === -1) return false;
      const tierIndex = ORDERED_TIERS.indexOf(Flags.tier);
      return tierIndex <= maxTierIndex;
   }
}
// Define the flags here.
export const Flag = {
   // General
   ENABLE_BRANDING_SETTINGS: Flags.enableThrough(Tier.DEV),
   ENABLE_CORE_API_AUTH: Flags.enableThrough(Tier.PROD),

   // Workforce Planning v1
   ENABLE_WORKFORCE_PLANNING_V1: Flags.enableThrough(Tier.DEV),

   // Assignment Modal
   ENABLE_TAGS_CORE_API: Flags.enableThrough(Tier.PROD),

   // Batch Actions
   ENABLE_BATCH_DELETE: Flags.enableThrough(Tier.PROD),

   // Permissions Modal
   ENABLE_CORE_PERMISSIONS_MODAL: Flags.enableThrough(Tier.PROD),

   // Project List
   ENABLE_PROJECT_LIST_CORE: Flags.enableThrough(Tier.PROD),

   // View Models
   ENABLE_COMPANY_VIEW_MODEL_CORE: Flags.enableThrough(Tier.NONE),
};
