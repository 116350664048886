import { Dom } from "@/lib/utils/dom"
import { ObservableData } from "@/lib/utils/observable-data"

export class PageCoverManager
   constructor: ->
      @activePageCover = null
      @callback = null
      @pageCoverData = null
      @$pageCover_ = null
      @animatingPageCoverIn = false
      @isShowingPageCover = ko.observable(false)

   initialize: ->
      @$pageCover_ = $(PageCoverManager.PAGE_COVER_HTML)
      @$pageCover_.hide()
      $("body").append(@$pageCover_)

   showPageCover: (pageCover, data, options, callback) ->
      assertArgs(arguments, Object, optional(Object), optional(Object), optional(Function))
      throw Error("PageCoverManager has not been initialize") unless @$pageCover_
      throw Error("A page cover is already visible") if @activePageCover?
      @pageCoverData = new ObservableData(data or {})
      @callback = callback or ->

      # Create page cover from panes.
      @activePageCover = pageCover

      @activePageCover.initialize(@, @pageCoverData)
      @bindPageCover(@activePageCover)

      # Set bottom property for page cover
      @$pageCover_.css({bottom: $(window).height() - @$pageCover_.offset().top})

      @activePageCover.willBecomeVisible()
      @animatingPageCoverIn = true
      @animateInPageCover_ =>
         @animatingPageCoverIn = false
         @activePageCover.hasBecomeVisible()

   ### Called by a page cover to indicate that the user cancelled the page cover. ###
   pageCoverCancelled: =>
      @closePageCover_("cancelled")

   pageCoverFinished: =>
      @closePageCover_("finished")

   forcePageCoverFinished: =>
      return unless @activePageCover?
      @activePageCover.willBecomeHidden()
      @isShowingPageCover(false)
      @$pageCover_.hide()
      @$pageCover_.removeClass('page-cover--visible')
      @activePageCover.hasBecomeHidden()
      @unbindPageCover()
      pageCover = @activePageCover
      @activePageCover = null
      @callback(pageCover, status, @pageCoverData)

   maybeCancelPageCover: (done) =>
      return done() unless @activePageCover?
      @activePageCover.willBecomeHidden()
      @animateOutPageCover_ =>
         @activePageCover.hasBecomeHidden()
         @unbindPageCover()
         pageCover = @activePageCover
         @activePageCover = null
         @callback(pageCover, status, @pageCoverData)
         done()

   closePageCover_: (status) =>
      assertArgs(arguments, String)
      @activePageCover.willBecomeHidden()
      @animateOutPageCover_ =>
         @activePageCover.hasBecomeHidden()
         @unbindPageCover()
         pageCover = @activePageCover
         @activePageCover = null
         @callback(pageCover, status, @pageCoverData)

   bindPageCover: (pageCover) ->
      content = Dom.getTemplateString(pageCover.template())
      throw Error("Couldn't find template: #{pageCover.template()}") unless content
      @$pageCover_.append(content)
      ko.applyBindings(pageCover, @$pageCover_[0])

   unbindPageCover: ->
      ko.cleanNode(@$pageCover_[0])
      @$pageCover_.empty()

   animateInPageCover_: (done) ->
      assertArgs(arguments, Function)
      @isShowingPageCover(true)
      @$pageCover_.one "transitionend", =>
         done()
      @$pageCover_.show()
      @$pageCover_.addClass('page-cover--visible')

   animateOutPageCover_: (done) ->
      assertArgs(arguments, Function)
      # To catch when a page cover hasn't had time to fully animate in yet.
      return done() if @activePageCover? and @animatingPageCoverIn
      @isShowingPageCover(false)
      @$pageCover_.one "transitionend", =>
         @$pageCover_.hide()
         done()
      @$pageCover_.removeClass('page-cover--visible')


PageCoverManager.PAGE_COVER_HTML = "<div class=\"page-cover\"></div>"

export pageCoverManager = new PageCoverManager()